import { ReactNode, useEffect } from 'react';

import cx from 'classnames';
import ModalFeedbackWhenAddToCartContainer from 'containers/ModalFeedbackWhenAddToCartContainer/ModalFeedbackWhenAddToCartContainer';
// eslint-disable-next-line import/no-unresolved
import { Except } from 'type-fest';
import { getMenu, getPreviewMenu } from 'utils/getMenu';

import CookieAlert from 'components/CookieAlert/CookieAlert';
import FooterMainFragment from 'components/Footer/FooterMainFragment/FooterMainFragment';
import FooterWithChampionClubBenefits from 'components/Footer/FooterWithChampionClubBenefits/FooterWithChampionClubBenefits';
import FooterWithSocial from 'components/Footer/FooterWithSocial';
import HeaderMenu, { HeaderMenuProps } from 'components/HeaderMenu/HeaderMenu';
import { useApp } from 'contexts/appContext';
import { ICauseMessage } from 'models/ICauseMessage';
import { IChampionClubBenefit } from 'models/IChampionClubBenefit';
import { IDraw } from 'models/IDraw';
import { IMenu } from 'models/IMenu';
import { IPersona } from 'models/IPersona';
import { IStandardSettingObject } from 'models/IStandardSetting';

import FOOTER_MENU_STRUCTURE from '../constants/footerMenu';

export interface MainLayoutProps {
  className?: string;
  headerProps?: Except<HeaderMenuProps, 'menu'>;
  championClubBenefits?: IChampionClubBenefit[];
  homeDraws?: IDraw[];
  autoDraws?: IDraw[];
  previewDraws?: IDraw[];
  settings?: IStandardSettingObject;
  randomCauseMessage?: ICauseMessage;
  thankYouCauseMessage?: ICauseMessage;
  persona?: IPersona;
  children: ReactNode;
  shouldDisplay?: { footerWithSocial?: boolean; footerWithMenu?: boolean };
}

const MainLayout = ({
  headerProps,
  championClubBenefits = [],
  children,
  className,
  homeDraws = [],
  autoDraws = [],
  previewDraws = [],
  settings = {},
  persona,
  shouldDisplay = { footerWithSocial: true, footerWithMenu: true },
}: MainLayoutProps) => {
  const {
    actions: { setPersona },
  } = useApp();

  // TODO: this is forcing evertyhing to re-load again on the client-side
  // is this the right place to implement setPersona?
  useEffect(() => {
    if (persona) {
      setPersona(persona);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerMenu: IMenu[] =
    previewDraws.length > 0
      ? [...getMenu(homeDraws, autoDraws), getPreviewMenu(previewDraws)]
      : getMenu(homeDraws, autoDraws);

  const footerMenu: IMenu[] = FOOTER_MENU_STRUCTURE;

  return (
    <div className={cx('main-layout flex-column d-flex', className)}>
      <HeaderMenu {...headerProps} menu={headerMenu} />

      <div className="main-layout-content flex-grow-1 position-relative">
        {children}
      </div>

      <FooterWithChampionClubBenefits
        championClubBenefits={championClubBenefits}
      />

      {shouldDisplay.footerWithSocial && (
        <FooterWithSocial
          facebookLotteryPage={settings.facebookLotteryPage?.text_value}
          instagramLotteryUser={settings.instagramLotteryPage?.text_value}
          twitterLotteryUser={settings.twitterLotteryPage?.text_value}
          youtubeLotteryChannel={settings.youtubeLotteryPage?.text_value}
        />
      )}
      {shouldDisplay.footerWithMenu && <FooterMainFragment menu={footerMenu} />}

      <CookieAlert />
      <ModalFeedbackWhenAddToCartContainer />
    </div>
  );
};

export default MainLayout;
