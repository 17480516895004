import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, MouseEvent, useEffect, useMemo } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { AiOutlineUser } from 'react-icons/ai';
import { CgClose } from 'react-icons/cg';
import { FaChevronDown, FaChevronRight, FaChevronLeft } from 'react-icons/fa';

import cx from 'classnames';
import CartButtonContainer from 'containers/CartButtonContainer/CartButtonContainer';
import mobileAndTabletCheck from 'utils/checkMobile';

import { HamburgerMenu, Logo } from 'components/Icons';
import { useAuth } from 'contexts/authContext';
import { IMenu } from 'models/IMenu';

import MenuStructure from '../MenuStructure/MenuStructure';

import s from './HeaderMenu.module.scss';

export interface HeaderMenuProps {
  menu: IMenu[];
  className?: string;
}

const HeaderMenu = ({ menu, className }: HeaderMenuProps) => {
  const router = useRouter();

  const displayActionsOnTheRight = !router.pathname.includes('/transaction');

  const [drawNumber, setDrawNumber] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (router.pathname === '/lottery/[draw_no]') {
      setDrawNumber(router.query.draw_no as string | undefined);
    }
  }, [router.pathname, router.query.draw_no]);

  const { user, logout } = useAuth();
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const [activeMenuHref, setActiveMenuHref] = useState<string>();
  const secondLevel = menu.find(
    (menuItem: IMenu) => menuItem.href === activeMenuHref,
  );

  const appendedMobileMenus = useMemo(
    () => [...menu, { label: 'Call us 1800 642 999', href: 'tel:1800642999' }],
    [menu],
  );

  return (
    <div
      className={cx(
        s['header-menu'],
        { [s.opened]: mobileMenuOpened },
        'header-menu',
        className,
      )}
    >
      <div
        className={cx(s['header-menu-primary-bar'], 'py-0 py-md-2')}
        onMouseEnter={() => setActiveMenuHref(undefined)}
      >
        <Container>
          <nav className="d-flex justify-content-between align-items-center">
            <Link href="/" passHref prefetch={false}>
              <a className={s['header-menu-logo']}>
                <div className={s['header-menu-logo-img']}>
                  <Logo size={4.8} />
                </div>
              </a>
            </Link>

            <div className="d-flex align-items-center">
              <div className="d-none d-md-flex align-items-center mr-3">
                <Link href="/contact-us" prefetch={false}>
                  <a
                    className={cx(
                      'btn btn-sm btn-link pr-0 pr-md-2 text-primary',
                      s['header-btn-link'],
                    )}
                  >
                    Contact us
                  </a>
                </Link>

                <a
                  href="tel:1800642999"
                  className={cx(
                    'btn btn-sm btn-link pr-0 pr-md-2 border-left rounded-l-none text-primary',
                    s['header-btn-link'],
                  )}
                >
                  Call us 1800 642 999
                </a>
              </div>

              {user?.email ? (
                <>
                  <Col className={cx('btn-sm  pr-0 pr-md-2 text-primary')}>
                    Welcome back, {user?.displayName}{' '}
                    <AiOutlineUser color="var(--primary)" />
                  </Col>

                  <Button
                    as="a"
                    variant="link"
                    size="sm"
                    className={cx(
                      'pr-0 pr-md-2 text-primary',
                      s['header-btn-link'],
                    )}
                    onClick={logout}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <Link href="/signin" passHref prefetch={false}>
                  <Button
                    as="a"
                    variant="link"
                    size="sm"
                    className={cx(
                      'pr-0 pr-md-2 text-primary',
                      s['header-btn-link'],
                    )}
                  >
                    Sign in
                  </Button>
                </Link>
              )}
            </div>
          </nav>
        </Container>
      </div>

      <div className={cx(s['header-menu-second-bar'], 'd-flex flex-column')}>
        <div className={cx(s['header-menu-second-bg-primary'], 'py-2')}>
          <Container>
            <div className="d-flex justify-content-end justify-content-md-between align-items-center flex-row flex-xl-row flex-wrap flex-xl-nowrap align-items-md-end align-items-xl-center flex-md-column-reverse">
              <nav className="d-md-flex d-none w-100 pl-0 pl-xl-9">
                {!!menu.length && (
                  <MenuStructure
                    className="d-flex align-items-center"
                    menu={menu}
                    icon={<FaChevronDown className="ml-1" />}
                    menuClass="text-white"
                    activeMenuClass="text-secondary"
                    activeMenuHref={activeMenuHref}
                    menuItemProps={{
                      onMouseEnter: (menuItem: IMenu) => {
                        setActiveMenuHref(menuItem.href);
                      },
                      onClick: (menuItem: IMenu, ev: MouseEvent) => {
                        if (mobileAndTabletCheck() && menuItem?.items?.length) {
                          setActiveMenuHref(menuItem.href);
                          ev.preventDefault();
                        }
                      },
                    }}
                  />
                )}
              </nav>

              {displayActionsOnTheRight && (
                <div
                  className={cx(
                    'd-flex flex-md-shrink-0 flex-shrink-1 flex-grow-0 flex-md-grow-1',
                    s.userMenuOnTop,
                  )}
                >
                  <CartButtonContainer />

                  <Link
                    href={
                      drawNumber
                        ? `/buy-now?focusOnDraw#${drawNumber}`
                        : '/buy-now'
                    }
                    scroll={false}
                    prefetch={false}
                    passHref
                  >
                    <Button as="a" variant="danger" className="ml-3 px-3">
                      BUY NOW
                    </Button>
                  </Link>
                </div>
              )}

              <Button
                onClick={() => setMobileMenuOpened(!mobileMenuOpened)}
                type="button"
                variant="link"
                aria-label="Toggle navigation"
                className="d-sm-block d-md-none p-2"
              >
                {mobileMenuOpened ? (
                  <CgClose color="yellow" size="20px" />
                ) : (
                  <HamburgerMenu type="yellow" />
                )}
              </Button>
            </div>
          </Container>
        </div>
        {mobileMenuOpened && (
          <nav
            className={cx(
              s['mobile-navigation'],
              'd-md-none d-flex flex-column flex-grow-1 overflow-auto',
            )}
          >
            {displayActionsOnTheRight && (
              <Container
                className={cx(
                  'p-4 justify-content-center',
                  s.userMenuSmallScreen,
                )}
              >
                <CartButtonContainer />
                <Link href="/buy-now" prefetch={false}>
                  <Button as="a" variant="danger" className="ml-3 px-3">
                    BUY NOW
                  </Button>
                </Link>
              </Container>
            )}
            <div className="bg-white">
              <Container className="d-flex flex-column p-4">
                {user?.email ? (
                  <>
                    <Button
                      variant="outline-primary"
                      className="text-center"
                      onClick={logout}
                    >
                      Sign out of your account
                    </Button>
                  </>
                ) : (
                  <>
                    <Link href="/signin" passHref prefetch={false}>
                      <Button
                        as="a"
                        variant="outline-primary"
                        className="text-center d-block"
                      >
                        Sign in to your account
                      </Button>
                    </Link>
                    <Link href="/signin" passHref prefetch={false}>
                      <a className="text-center mt-2 d-block font-weight-semi-bold text-decoration-underline">
                        No account? Create one today
                      </a>
                    </Link>
                  </>
                )}
              </Container>
            </div>
            <div className="d-flex flex-nowrap flex-grow-1">
              <MenuStructure
                className="d-flex flex-column flex-shrink-0 p-4 w-100"
                menu={appendedMobileMenus}
                menuClass="text-white"
                activeMenuClass="text-secondary"
                activeMenuHref={activeMenuHref}
                icon={<FaChevronRight />}
                menuItemProps={{
                  onClick: (menuItem: IMenu, event: Event) => {
                    if (menuItem?.items?.length) {
                      event.preventDefault();
                    } else {
                      setMobileMenuOpened(false);
                    }
                    setActiveMenuHref(
                      menuItem.href === activeMenuHref
                        ? undefined
                        : menuItem.href,
                    );
                  },
                }}
              />

              {!!secondLevel?.items?.length && (
                <div
                  className={cx(
                    'bg-white w-100 flex-shrink-0',
                    s['second-level-menu-mobile'],
                  )}
                >
                  <div className="bg-light-grey p-4">
                    <div className="d-flex align-items-center">
                      <Button
                        variant="link"
                        className="py-0"
                        onClick={() => {
                          setActiveMenuHref(undefined);
                        }}
                      >
                        <FaChevronLeft />
                      </Button>
                      {secondLevel.href && (
                        <Link href={secondLevel.href} passHref prefetch={false}>
                          {/* TODO:https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component  */}
                          {/*  eslint-disable-next-line */}
                          <a
                            className="font-weight-semi-bold"
                            onClick={() => {
                              setActiveMenuHref(undefined);
                              setMobileMenuOpened(false);
                            }}
                          >
                            {secondLevel.label}
                          </a>
                        </Link>
                      )}
                    </div>
                  </div>

                  <div className="p-4">
                    <MenuStructure
                      onClick={() => {
                        setActiveMenuHref(undefined);
                        setMobileMenuOpened(false);
                      }}
                      multipleLevels
                      menu={secondLevel.items}
                      menuItemProps={{
                        className: 'd-block font-weight-semi-bold py-1 px-3',
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </nav>
        )}
        {!!secondLevel?.items?.length && (
          <div
            className={cx(
              s['second-level-menu'],
              'bg-white mb-n4 d-md-flex d-none',
            )}
            onMouseLeave={() => setActiveMenuHref(undefined)}
          >
            <Container fluid="lg">
              <Row className="py-0 py-lg-3">
                <Col
                  lg={6}
                  className={cx(
                    s['menu-first-level-description-desktop'],
                    'py-3 pl-4 pl-xl-10',
                  )}
                >
                  {secondLevel.href && (
                    <Link href={secondLevel.href} passHref prefetch={false}>
                      {/* eslint-disable-next-line */}
                      <a
                        onClick={() => {
                          setActiveMenuHref(undefined);
                          setMobileMenuOpened(false);
                        }}
                        className="font-weight-semi-bold mb-3 d-block d-xl-none"
                      >
                        {secondLevel.label}
                      </a>
                    </Link>
                  )}
                  {secondLevel.description && <p>{secondLevel.description}</p>}
                </Col>
                <Col lg={6} className="py-3">
                  <MenuStructure
                    onClick={() => {
                      setActiveMenuHref(undefined);
                    }}
                    menu={secondLevel.items}
                    multipleLevels
                    menuItemProps={{
                      className: 'd-block font-weight-semi-bold py-1 px-3',
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderMenu;
