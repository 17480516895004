import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

interface IProps {
  facebookLotteryPage?: string;
  instagramLotteryUser?: string;
  twitterLotteryUser?: string;
  youtubeLotteryChannel?: string;
  buttonProps?: any;
  iconProps?: any;
}
const SocialMediaButtons: FC<IProps> = ({
  facebookLotteryPage,
  instagramLotteryUser,
  twitterLotteryUser,
  youtubeLotteryChannel,
  buttonProps,
  iconProps,
}) => {
  return (
    <>
      <Button
        className="p-0 mr-3"
        as="a"
        href={`https://www.facebook.com/${facebookLotteryPage}`}
        target="_blank"
        {...(buttonProps || {})}
      >
        <FaFacebook color="var(--secondary)" size={40} {...(iconProps || {})} />
      </Button>
      <Button
        className="p-0 mr-3"
        as="a"
        href={`https://www.instagram.com/${instagramLotteryUser}`}
        target="_blank"
        {...(buttonProps || {})}
      >
        <FaInstagram
          color="var(--secondary)"
          size={40}
          {...(iconProps || {})}
        />
      </Button>
      <Button
        className="p-0 mr-3"
        as="a"
        href={`https://twitter.com/${twitterLotteryUser}`}
        target="_blank"
        {...(buttonProps || {})}
      >
        <FaTwitter color="var(--secondary)" size={40} {...(iconProps || {})} />
      </Button>
      <Button
        className="p-0"
        as="a"
        href={`https://www.youtube.com/channel/${youtubeLotteryChannel}`}
        target="_blank"
        {...(buttonProps || {})}
      >
        <FaYoutube color="var(--secondary)" size={40} {...(iconProps || {})} />
      </Button>
    </>
  );
};
export default SocialMediaButtons;
