import Link from 'next/link';
import { Button, Modal, ModalProps } from 'react-bootstrap';

export interface IModalFeedbackWhenAddToCartProps extends ModalProps {
  onHide(): any;
  onContinueShopping?: () => any;
  onViewCart?: () => any;
  isLoading?: boolean;
}

const ModalFeedbackWhenAddToCart = ({
  onContinueShopping = () => {},
  onViewCart = () => {},
  isLoading,
  ...props
}: IModalFeedbackWhenAddToCartProps) => {
  return (
    <Modal {...props} contentClassName="bg-secondary p-4" size="lg">
      <div>
        <h5 className="text-center mb-3 large-body">
          Your tickets have been added to your cart!
        </h5>
        <p className="text-center mb-5">What would you like to do next?</p>
        <div className="d-flex flex-column align-items-center">
          <Link href="/transaction/cart" passHref prefetch={false}>
            <Button
              data-cy="btn-confirm-continue-shopping-modal-view-cart"
              onClick={onViewCart}
              as="a"
              variant="danger"
              className="px-7 mb-2"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'View Cart'}
            </Button>
          </Link>
          <Button
            data-cy="btn-confirm-continue-shopping-modal-continue-shopping"
            onClick={onContinueShopping}
            variant="link"
            className="text-black"
          >
            Continue Shopping
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFeedbackWhenAddToCart;
