import ModalFeedbackWhenAddToCart, {
  IModalFeedbackWhenAddToCartProps,
} from 'components/ModalFeedbackWhenAddToCart/ModalFeedbackWhenAddToCart';
import { useCart } from 'contexts/cartContext';

const ModalFeedbackWhenAddToCartContainer = (
  props: Omit<
    IModalFeedbackWhenAddToCartProps,
    'onHide' | 'onContinueShopping'
  >,
) => {
  const {
    state: { displayModal, isLoading },
    actions: { toggleModal },
  } = useCart();

  return (
    <ModalFeedbackWhenAddToCart
      {...props}
      onHide={() => {
        toggleModal();
      }}
      onViewCart={() => {
        toggleModal();
      }}
      centered
      show={displayModal}
      onContinueShopping={toggleModal}
      isLoading={isLoading}
    />
  );
};
export default ModalFeedbackWhenAddToCartContainer;
