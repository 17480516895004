import { Container } from 'react-bootstrap';

import cx from 'classnames';

import MenuStructure from 'components/MenuStructure/MenuStructure';
import { IMenu } from 'models/IMenu';

import s from './FooterMainFragment.module.scss';

interface IProps {
  menu: IMenu[];
}

const FooterMainFragment = ({ menu }: IProps) => {
  return (
    <>
      <div className={cx('bg-light-grey py-3', s.bgLightGrey)}>
        <Container>
          <MenuStructure
            className="d-flex justify-content-center align-items-start align-items-sm-center flex-column flex-sm-row list-style-type-none p-0 w-100 flex-wrap"
            menu={menu}
            activeMenuClass="text-secondary"
          />
        </Container>
      </div>

      <div className="bg-white py-3">
        <Container className="d-flex flex-column align-items-center justify-content-between">
          <p className="mb-0">
            You must be 18 years or over to enter Surf Life Saving Lotteries
          </p>
          <div className="d-flex">
            <p className="px-2">Copyright Surf Life Saving Foundation 2020</p>
            <span className="px-2">ACN 159 849 591</span>
            <span className="px-2">ABN 47 945 812 553</span>
          </div>
        </Container>
      </div>
    </>
  );
};

export default FooterMainFragment;
