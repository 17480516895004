import { FC, useState } from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';

import cx from 'classnames';
import Cookies from 'universal-cookie';

import { Times } from 'components/Icons';

import s from './CookieAlert.module.scss';

const cookies = new Cookies();

const CookieAlert: FC = () => {
  const [cookieAccepted, setCookieAccepted] = useState(
    !!cookies.get('slsf-cookie-accept'),
  );
  const handleClose = () => {
    setCookieAccepted(true);
    cookies.set('slsf-cookie-accept', true);
  };

  if (cookieAccepted) {
    return <></>;
  }

  return (
    <div className={cx(s['cookie-alert'], 'bg-secondary', 'p-1')}>
      <Container>
        <Row className="m-0 justify-content-center align-items-center">
          <Col>
            <p className="mb-0">
              By browsing this site you accept cookies used to improve and
              personalise our services and marketing. Read our updated privacy
              policy for more about how we use your data.
            </p>
          </Col>
          <Col xs="auto">
            <Button className="btn-block" variant="link" onClick={handleClose}>
              <div className="d-flex align-items-center">
                <span className="mr-1">Close</span> <Times size={0.5} />
              </div>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CookieAlert;
