import { IMenu } from 'models/IMenu';

const FOOTER_MENU_STRUCTURE: IMenu[] = [
  // {
  //   label: 'Prize home lotteries',
  //   href: '/lottery/home',
  //   cyID: 'home-lotteries',
  // },
  // {
  //   label: 'Auto lotteries',
  //   href: '/lottery/auto',
  //   cyID: 'auto-lotteries',
  // },
  {
    label: 'Champions Club',
    href: '/subscribe',
    cyID: 'champions-club',
  },
  {
    label: 'Winners',
    href: '/winners',
    cyID: 'winners',
  },
  {
    label: 'FAQs',
    href: '/faqs',
    cyID: 'faqs',
  },
  {
    label: 'Terms and Conditions',
    href: '/terms-conditions',
    cyID: 'terms-conditions',
  },
  {
    label: 'Privacy Policy',
    href: '/privacy',
    cyID: 'privacy-policy',
  },
  // {
  //   label: 'Collection Statement',
  //   href: '/collection-statement',
  //   cyID: 'collection-statement',
  // },
  {
    label: 'Site Map',
    href: '/site-map',
    cyID: 'site-map',
  },
  {
    label: 'Contact Us',
    href: '/contact-us',
    cyID: 'contact-us',
  },
  {
    label: 'Responsible Gambling',
    href: '/responsible-gambling',
    cyID: 'responsible-gambling',
  },
];

export default FOOTER_MENU_STRUCTURE;
