interface Props {
  size?: number;
}

const Logo = ({ size = 1 }: Props) => {
  return (
    <svg
      width={`${size}rem`}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 2003 2003"
      enableBackground="new 0 0 2003 2003"
    >
      <g>
        <ellipse
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -414.8347 1001.5005)"
          fill="#F40000"
          fillRule="evenodd"
          clipRule="evenodd"
          cx="1001.5"
          cy="1001.5"
          rx="972.4"
          ry="972.4"
        />
        <g>
          <path
            fill="#FFFFFF"
            d="M1922,612.7c-50.3-118.9-122.3-225.8-214-317.5c-91.8-91.7-198.7-163.7-317.6-214.2
			c-123.2-52.1-253.9-78.5-388.8-78.5C866.7,2.5,735.8,28.9,612.7,81c-118.9,50.4-225.8,122.5-317.5,214.2
			C203.3,386.9,131.4,493.7,81,612.7c-52.1,123.2-78.5,254-78.5,388.8c0,135,26.4,265.7,78.5,388.8
			c50.3,119.1,122.3,225.8,214.2,317.6c91.7,91.7,198.6,163.7,317.5,214c123.2,52.1,254,78.5,388.8,78.5
			c135,0,265.7-26.4,388.8-78.5c118.9-50.3,225.8-122.3,317.6-214c91.7-91.8,163.7-198.6,214-317.6
			c52.1-123.2,78.5-253.9,78.5-388.8C2000.5,866.7,1974.1,735.8,1922,612.7z M1001.5,1940c-517.5,0-938.3-421-938.3-938.5
			c0-517.3,420.9-938.3,938.3-938.3c517.5,0,938.3,421,938.3,938.3C1939.8,1519,1519,1940,1001.5,1940z"
          />
        </g>
        <path
          fill="#0030C5"
          d="M1538.1,1196.3c-25.4,69.5-63.3,132.5-112.8,187.2c-51.2,56.6-112.6,101.7-182.5,134.2
		c-76.4,35.5-157.4,53.5-240.9,53.5c-107.3,0-212.2-30-303.4-86.8c-93.5-58.3-167.8-142-214.6-242.2
		c-32.6-69.7-50.5-143.7-53.3-219.8c-2.7-73.6,8.8-146.2,34.2-215.7c25.4-69.5,63.3-132.5,112.8-187.2
		c51.2-56.6,112.6-101.7,182.5-134.2c76.3-35.5,157.4-53.5,241-53.5h0c107.2,0,212.1,30,303.4,86.8c93.5,58.3,167.7,142,214.6,242.2
		c32.6,69.7,50.5,143.7,53.3,219.8C1575,1054.2,1563.5,1126.8,1538.1,1196.3z"
        />
        <path
          fill="#FFFFFF"
          d="M1060.3,1100.7L1060.3,1100.7c-0.6-1.3-1.2-2.4-3.3-2.9c-38.3-9.5-78-32.6-113.6-66.4
		c-29.4-28-49.4-56.8-66.9-92.2c-1.2-2.5-2.8-2.7-6-1.2l0,0l-141.9,66.1c-2.7,1.3-3.5,3-2.1,5.7c37.1,69.1,86.4,97.5,92.4,161.4
		c5.7,60.8-56.6,133.9-66.2,145.1c-1.3,1.5-1.4,3.3-0.5,4.5c1.4,1.9,4.4,0.1,6-0.8c109.3-62.9,241.5-16.3,275.5,117.7
		c0.4,1.7,1.6,4.3,3.9,4.1c1.9-0.2,3.2-1.5,3.2-5.1c0-63.3,1-133.5,27.9-184.3c9.3-17.5,22.9-33.2,36.9-42.7
		c2.7-1.8,3.2-3.5,2.3-5.5L1060.3,1100.7z"
        />
        <path
          fill="#FFD109"
          d="M944.2,847.7L836.8,613.8c-1.1-2.5-3.4-3.1-6-1.6c-120.7,71.2-173.4,217.8-126.9,348.7c0.7,2,2.6,3.7,5.4,2.3
		l232.8-108.5C944.5,853.6,945.8,851.1,944.2,847.7z"
        />
        <path
          fill="#FFFFFF"
          d="M1051.7,853.5l-131.5,61.3c-3,1.4-3.9,2.4-3,4.8c24.8,58.1,87,115.4,144.4,132.7c1.6,0.4,2.6-0.2,3.4-1.7
		c24.2-55.7,21.2-140.2-6.9-195.5C1056.5,852,1055.2,851.8,1051.7,853.5z"
        />
        <path
          fill="#F40000"
          d="M985.4,829.3c1.1,2.5,3,3.8,6.1,2.4L1228.9,721c2-0.9,2.9-3.3,1.4-5.6c-71-120.8-217.9-174.6-349.8-126.6
		c-2.3,0.9-4,3.1-2.6,6.1C877.9,594.9,984.5,827.2,985.4,829.3z"
        />
        <path
          fill="#FFFFFF"
          d="M1598.8,979.6c-2.9-79.9-21.6-157.5-55.7-230.7c-49-105.1-126.7-193-224.6-254.1c-95.4-59.6-205.1-91-317.3-91
		c0,0,0,0,0,0c-87.5,0-172.3,18.9-252.2,56.1c-73.1,34.1-137.4,81.5-191,140.9c-51.8,57.4-91.5,123.4-118,196.3
		c-26.5,72.9-38.6,149.1-35.8,226.3c2.9,79.9,21.6,157.5,55.7,230.7c49,105.1,126.7,193,224.6,254.1c95.4,59.6,205.1,91,317.4,91
		c87.4,0,172.3-18.9,252.2-56.1c73.1-34.1,137.4-81.5,191-140.9c51.8-57.4,91.5-123.4,118-196.3
		C1589.6,1132.9,1601.6,1056.8,1598.8,979.6z M1001.9,1538.4c-100.8,0-199.4-28.3-285.2-81.8c-87.9-54.9-157.7-133.8-201.7-228.2
		c-30.6-65.7-47.5-135.4-50.1-207.2c-2.5-69.3,8.3-137.7,32.2-203.3c23.9-65.5,59.5-124.9,106-176.4
		c48.1-53.3,105.8-95.9,171.5-126.5c71.8-33.5,148-50.4,226.5-50.4c100.8,0,199.4,28.3,285.1,81.8
		c87.9,54.9,157.7,133.8,201.7,228.2c30.6,65.7,47.5,135.4,50.1,207.2c2.5,69.3-8.3,137.7-32.2,203.3
		c-23.9,65.5-59.5,124.9-106,176.4c-48.1,53.3-105.8,95.9-171.5,126.5C1156.6,1521.5,1080.4,1538.4,1001.9,1538.4z"
        />
        <path
          fill="#FFFFFF"
          d="M1254.7,763.5c-1.4-2.9-2.8-3.4-5.9-1.9l-147.5,68.8c-3.6,1.7-3.7,2.9-2,6.3l-0.1-0.1
		c35.1,71,36.7,174,2.3,242c-0.6,1.1-0.6,2.5,0,3.9l49.5,107.8l0,0c1.3,2.8,2,3.8,6.2,3.6c32.4-1.8,66.1,4.8,84.3-3
		c15.1-6.2,25.7-20.8,25.7-38c0.5-15.1-6.9-31.8-4.2-45c2.2-10.6,17-14.8,15.4-23.8c-1.6-9-15.3-6.6-20.6-7.2
		c-3.7-0.4-3.8-4.1,0.1-4.9c2.8-0.6,21.9-1.4,24.7-12.3c3.1-11.7-11.2-19.5-8.3-30.2c4.9-18.4,40.9-5.5,48.7-25.8
		c10.2-26.3-43.1-56.7-49.4-100.4C1269.1,871.2,1281.4,825.6,1254.7,763.5z"
        />
        <polygon
          fill="#FFFFFF"
          points="1155.7,1313 1170.7,1313 1170.7,1351.9 1176.7,1351.9 1176.7,1313 1191.6,1313 1191.6,1307.7 
		1155.7,1307.7 	"
        />
        <path
          fill="#FFFFFF"
          d="M1218.3,1345.1h-0.1l-12.6-37.4h-8.6v44.2h5.8v-26.1c0-1.3-0.1-6.9-0.1-10.9h0.1l12.5,37h5.9l12.4-37.1h0.1
		c0,4-0.1,9.6-0.1,11v26.1h5.8v-44.2h-8.6L1218.3,1345.1z"
        />
        <g>
          <path
            fill="#FFFFFF"
            d="M1378.2,214.6c0.8-1.4,2.7-1.9,4.1-1.1l142,81.4c1.4,0.8,1.9,2.7,1.1,4.1l-16.8,29.3
			c-0.8,1.4-2.7,1.9-4.1,1.1l-101.2-58l-23.3,40.7l94.2,54c1.4,0.8,1.9,2.7,1.1,4.1l-17,29.6c-0.8,1.4-2.7,1.9-4.1,1.1l-94.2-54
			l-38,66.3c-0.8,1.4-2.7,1.9-4.1,1.1l-38.2-21.9c-1.4-0.8-1.9-2.7-1.1-4.1L1378.2,214.6z"
          />
          <path
            fill="#FFFFFF"
            d="M629.2,378.4c13.1,12.2,30.8,15.6,60.4,0.9c28.7-14.2,32.7-27.1,26.8-39c-6.3-12.7-18.4-15.7-60.6-3
			c-69.5,20.8-91.5,5.8-103.8-19c-18.3-36.9,7.9-73.4,51.5-95c54.3-26.9,86.5-13.2,102.7,4.2c0.7,0.8,1.3,2.9-0.4,4.2l-35.6,25.5
			c-1.7,1.2-3.2,0.3-3.7-0.3c-7.4-7.3-19.9-14.2-48.2-0.2c-21.4,10.6-28.8,22.8-22.9,34.7c5.3,10.7,15.6,12.3,53.3,1.3
			c76.4-22.2,97.9-6.5,111.2,20.3c15.9,32,3.6,70.4-58.4,101.1c-52,25.8-88.6,17.9-111.5-6.1c-0.8-0.8-1.8-2.7,0.4-4.4l35.3-25.6
			C627.5,376.9,628.8,378,629.2,378.4z"
          />
          <path
            fill="#FFFFFF"
            d="M1229.8,329.6l1.9-10.1c6.8-36.4-13.5-48.4-21.2-53.3c21.3-2.6,39.3-15.2,44.2-41.4
			c6.5-34.8-15.9-62.9-60.5-71.2l-109.7-20.5c-1.7-0.3-3,0.7-3.3,2.4l-36.8,197.1c-0.3,1.9,0.5,2.9,2.3,3.3l43.1,8.1
			c1.6,0.3,2.9-0.4,3.2-2l14.1-75.6l41.2,7.7c29.5,5.5,35.5,15.5,31.6,36l-1.2,6.7c-3.7,20-5.6,31.8-4.5,41c0.1,0.7,0.6,2.1,2.4,2.5
			l44.7,8.4c2.3,0.4,3.5-1.8,3.5-3.1C1224.8,355.5,1227.5,341.9,1229.8,329.6z M1164.7,238.9l-50.7-9.5l9.5-50.8l51.1,9.5
			c23.2,4.3,32.1,15.4,29.1,31.4C1200.6,236.7,1188.6,243.4,1164.7,238.9z"
          />
          <path
            fill="#FFFFFF"
            d="M906.9,349.5c-60.7,8.6-99.7-16-108.5-77.9l-16.2-114.2c-0.2-1.6,0.9-3,2.4-3.2l43.9-6.2
			c1.6-0.2,3,0.9,3.2,2.5l16,112.5c6.1,42.9,27,51.7,53.7,47.9s44.3-18,38.2-60.9l-16-112.5c-0.2-1.6,0.9-3.1,2.4-3.3l43.9-6.2
			c1.6-0.2,3,0.9,3.2,2.4l15.7,110.5C997.7,302.7,967.6,340.9,906.9,349.5z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M339.3,1080.8c1.6-0.4,3.2,0.6,3.6,2.2l10.5,42.7c0.4,1.6-0.6,3.2-2.2,3.6l-158.4,38.9l21.6,88.1
			c0.4,1.6-0.6,3-2.3,3.1l-33.2,8.1c-2.1,0.5-3.1-0.1-3.5-1.7l-32.8-133.7c-0.4-1.6,0.6-3.2,2.2-3.6L339.3,1080.8z"
          />
          <path
            fill="#FFFFFF"
            d="M402.8,1281.9c0.7,1.5-0.1,3.3-1.6,3.9l-184,79c-1.5,0.7-3.3-0.1-3.9-1.6l-17.4-40.5
			c-0.7-1.5,0.1-3.3,1.6-3.9l184-79c1.5-0.7,3.3,0.1,3.9,1.6L402.8,1281.9z"
          />
          <path
            fill="#FFFFFF"
            d="M628.7,1685.1c-0.9,1.4-2.8,1.7-4.2,0.8l-93.1-62.4l-26.1,39l99,66.3c1.9,1.3,2.2,2.9,0.8,4.9l-18.6,27.8
			c-1.1,1.2-3.1,1.5-4.5,0.6l-138.1-92.5c-1.4-0.9-1.7-2.8-0.8-4.2l111.4-166.3c0.9-1.4,2.8-1.7,4.2-0.8l137.4,92
			c1.4,0.9,1.7,2.8,0.8,4.2l-19,28.3c-0.9,1.4-2.8,1.7-4.2,0.8l-98.3-65.8l-21.7,32.4l93.1,62.4c1.4,0.9,1.7,2.8,0.8,4.2
			L628.7,1685.1z"
          />
          <path
            fill="#FFFFFF"
            d="M1456.2,1486c1.2-1.1,3.1-1,4.2,0.3l132.5,150.1c1.1,1.2,1,3.1-0.3,4.2l-33,29.2c-1.2,1.1-3.1,1-4.2-0.3
			l-132.5-150c-1.1-1.2-1-3.1,0.3-4.2L1456.2,1486z"
          />
          <path
            fill="#FFFFFF"
            d="M1670.3,1559.4c-0.9,1.4-2.8,1.7-4.2,0.8l-165.6-112.5c-1.4-0.9-1.7-2.8-0.8-4.2l29.9-44
			c0.9-1.4,3-2.5,4.7-2.5l170,3.6l-118.3-78.7c-1.4-0.9-1.7-2.8-0.8-4.2l22.4-33c0.9-1.4,2.8-1.7,4.2-0.8l165.6,112.5
			c1.4,0.9,1.7,2.8,0.8,4.2l-27.9,41c-0.9,1.4-3,2.4-4.7,2.4l-181.7-7l128,85.3c1.4,0.9,1.7,2.8,0.8,4.2L1670.3,1559.4z"
          />
          <path
            fill="#FFFFFF"
            d="M424.2,1334.3c1.3-1,3.2-0.7,4.2,0.6l99,130.3c1,1.3,0.7,3.2-0.6,4.2l-26.9,20.5c-1.3,1-3.2,0.7-4.2-0.6
			l-70.6-92.9l-37.3,28.4l65.7,86.5c1,1.3,0.7,3.2-0.6,4.2l-27.2,20.6c-1.3,1-3.2,0.7-4.2-0.6l-65.7-86.5l-60.8,46.2
			c-1.3,1-3.2,0.7-4.2-0.6l-26.6-35.1c-1-1.3-0.7-3.2,0.6-4.2L424.2,1334.3z"
          />
          <path
            fill="#FFFFFF"
            d="M1854.5,1206c-16.4,64.8-71.9,97-130.4,82.2c-58.4-14.8-95.6-65.1-77.6-136.5c12.3-48.6,39.3-71.1,64.9-78.4
			c1.4-0.4,3.5,0.5,3.8,2l7,41.4c0.3,1.8-0.8,3.1-2.3,3.3c-11.4,2.2-29.1,10.7-36.8,41c-10.4,40.9,14.3,66.1,51.5,75.5
			c39.2,9.9,73.2-1.2,83.6-42c8.6-33.8-3.8-58.7-27.7-64.8l-3.2-0.8l-11.6,45.9c-0.4,1.7-1.8,2.5-3.5,2.1c-8-2-30.4-7.7-31.7-8
			c-1.6-0.4-2.5-1.9-2.1-3.5l22.8-90.1c0.4-1.7,1.7-2.5,3.5-2.1c5.6,1.1,18.4,3.9,29.6,7.3C1842,1095.1,1871.9,1137,1854.5,1206z"
          />
          <path
            fill="#FFFFFF"
            d="M1370.4,1789.7c-1.5,0.7-3.7,0.5-4.9-0.6l-163.4-142.3c-1.2-1.1-1.1-2.6,0.4-3.3l44.1-22.2
			c1.5-0.7,3.7-0.4,4.9,0.7l123.7,115l-18-168.4c-0.2-1.6,0.9-3.6,2.3-4.3l42.4-21.4c1.5-0.7,2.8,0,2.9,1.6l16.1,216.6
			c0.1,1.6-1,3.6-2.5,4.3L1370.4,1789.7z"
          />
          <path
            fill="#FFFFFF"
            d="M845.5,1797.6c4.1,17.4,17,30,49.8,34.2c31.8,4.1,42.2-4.5,43.9-17.7c1.8-14.1-6.6-23.2-48.8-36.1
			c-69.4-21.2-79.4-45.9-75.9-73.3c5.2-40.8,47.2-56.7,95.6-50.5c60.1,7.7,79.3,37,83.2,60.4c0.2,1.1-0.5,3.2-2.7,3.2l-43.7,1.5
			c-2.1,0.1-2.8-1.5-3-2.3c-2.1-10.2-8.7-22.9-40-26.9c-23.6-3-36.6,3-38.3,16.2c-1.5,11.8,6.2,18.8,43.7,30.6
			c75.9,23.9,85.1,48.9,81.3,78.6c-4.5,35.5-36,60.5-104.6,51.8c-57.5-7.4-83.7-34.2-89.5-66.9c-0.2-1.1,0-3.3,2.8-3.4l43.6-1.7
			C844.9,1795.3,845.3,1797,845.5,1797.6z"
          />
          <path
            fill="#FFFFFF"
            d="M1090.4,1819.4l-9.4,46.2c-0.3,1.6-1.9,3.2-3.6,3.4l-47.1,8c-1.6,0.3-2.7-0.8-2.3-2.4l46.1-211.3
			c0.4-1.6,2-3.2,3.6-3.4l62.4-10.6c1.6-0.3,3.7,0.6,4.6,2L1262,1835c0.9,1.4,0.3,2.8-1.3,3l-47.1,8c-1.6,0.3-3.7-0.6-4.5-2
			l-24.2-40.6L1090.4,1819.4z M1163,1766.4l-46.1-77.8l-18,88.7L1163,1766.4z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
