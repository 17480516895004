import { IMapLocation } from 'models/IMapLocation';

import { IDrawFAQ } from './IDrawFAQ';
import type { IDrawGallery } from './IDrawGallery';
import { IDrawHeroImage } from './IDrawHeroImage';
import { IDrawType } from './IDrawType';
import { IDrawWinner } from './IDrawWinner';
import { IFileProperties } from './IFileProperties';
import type { IPrizeFeature } from './IPrizeFeature';
import { IPrizeMarketEstimate } from './IPrizeMarketEstimate';
import type { ITicketBook } from './ITicketBook';
import type { ITicketRange } from './ITicketRange';

export enum DrawOutcomes {
  Archive = 1,
  Rejected = 0,
}

export enum DrawSteps {
  setup = 1,
  pendingApproval = 2,
  upComing = 3,
  open = 4,
  close = 5,
  results = 6,
}

export interface IDraw {
  id: number;
  draw_no: string;
  available_tickets?: number;
  draw_type_id?: number;
  draw_type?: IDrawType;
  total_first_prize_value?: number;
  first_prize: string;
  bonus_prize?: string;
  menu_label?: string;
  heading?: string;
  expert?: string;
  open_datetime?: string;
  close_datetime?: string;
  draw_datetime?: string;
  winner_announcement_datetime?: string;
  overview_heading?: string;
  overview_content?: string;
  prize_detail_heading?: string;
  prize_detail_content?: string;
  video?: string;
  virtual_tour?: string;
  floorplan?: IFileProperties;
  thumbnail?: IFileProperties;
  location_map?: IMapLocation;
  terms_and_conditions?: string;
  draw_icon?: IFileProperties;
  header_background_color?: string;
  draw_hero_images?: IDrawHeroImage[];
  draw_galleries?: IDrawGallery[];
  prize_features?: IPrizeFeature[];
  prize_market_estimates?: IPrizeMarketEstimate[];
  ticket_books?: ITicketBook[];
  ticket_ranges: ITicketRange[];
  draw_winners: IDrawWinner[];
  _workflow_outcome?: DrawOutcomes;
  _workflow_current_step: DrawSteps;
  featured_ind?: boolean;
  faqs: IDrawFAQ[];
}
