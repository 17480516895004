import Link from 'next/link';
import { FC } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { FiShoppingCart } from 'react-icons/fi';

import Steps from 'components/Transaction/Steps';
import { MyCartSteps, useCart } from 'contexts/cartContext';

import s from './CartButtonContainer.module.scss';

const CartButtonContainer: FC = () => {
  const {
    state: { userCart },
  } = useCart();

  return (
    <Link prefetch={false} href={Steps[MyCartSteps.myCart].route} passHref>
      <Button
        as="a"
        data-cy="cart-button-container"
        variant="secondary"
        className="position-relative px-3 btn-circle"
      >
        <FiShoppingCart
          className="ml-n1 mb-n1"
          size="1.5rem"
          color="var(--primary)"
        />
        {!!(userCart?.shopping_cart_items || []).length && (
          <Badge
            data-cy="cart-button-container-badge"
            className={s.cartButton}
            variant="danger"
          >
            {userCart!.shopping_cart_items?.reduce(
              (acc, { quantity }) => quantity + acc,
              0,
            )}
          </Badge>
        )}
      </Button>
    </Link>
  );
};
export default CartButtonContainer;
