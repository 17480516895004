import { IMenu } from 'models/IMenu';

const HOME_LOTTERIES_DESCRIPTION = `Surf Life Saving prize home lotteries give you the opportunity to win luxury homes in some of the most stunning locations in Australia, all while helping to keep lifesavers on our beaches.`;
const AUTO_LOTTERIES_DESCRIPTION = `Surf Life Saving car lotteries give you the opportunity to hit the open road, or track, in the name of adventure - all while helping to keep lifesavers on our beaches.`;
const MORE_DESCRIPTION = `Surf Life Saving lotteries give you the opportunity to win millions in prizes each year, all while helping to keep lifesavers on our beaches.`;

const MENU_STRUCTURE: IMenu[] = [
  {
    label: 'Prize home lotteries',
    href: '#/lottery/home',
    cyID: 'home-lotteries',
    description: HOME_LOTTERIES_DESCRIPTION,
  },
  {
    label: 'Car lotteries',
    href: '#/lottery/car',
    cyID: 'auto-lotteries',
    description: AUTO_LOTTERIES_DESCRIPTION,
  },
  {
    label: 'Never Miss A Draw',
    href: '/subscribe',
    cyID: 'champions-club',
  },
  {
    label: 'Winners',
    href: '/winners',
    cyID: 'winners',
  },
  {
    label: 'More',
    cyID: 'more',
    href: '#more',
    description: MORE_DESCRIPTION,
    items: [
      {
        label: 'News',
        href: '/news',
        cyID: 'news',
      },
      {
        label: 'FAQs',
        href: '/faqs',
        cyID: 'faqs',
      },
      {
        label: 'Contact Us',
        href: '/contact-us',
        cyID: 'contact-us',
      },
      {
        label: 'Terms and Conditions',
        href: '/terms-conditions',
        cyID: 'terms-conditions',
      },
      {
        label: 'Privacy Policy',
        href: '/privacy',
        cyID: 'privacy-policy',
      },
      {
        label: 'Responsible Gambling',
        href: '/responsible-gambling',
        cyID: 'responsible-gambling',
      },
    ],
  },
];
export default MENU_STRUCTURE;
