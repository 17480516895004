import Link from 'next/link';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { IChampionClubBenefit } from 'models/IChampionClubBenefit';

interface IProps {
  championClubBenefits?: IChampionClubBenefit[];
}

const FooterWithChampionClubBenefits = ({
  championClubBenefits = [],
}: IProps) => {
  // TODO: investigate possibility of loading club benefits on the client
  return (
    <footer>
      {championClubBenefits?.length > 0 && (
        <div className="bg-gold py-6">
          <Container>
            <Row className="mb-n4">
              <Col
                lg={6}
                className="justify-content-center d-flex flex-column align-items-center align-items-md-start mb-4"
              >
                <h3 className="mb-3 w-100 text-center text-md-left">
                  Unlock exclusive prizes!
                </h3>
                <img
                  loading="lazy"
                  style={{ maxWidth: '200px' }}
                  className="no-shadow py-2 px-3 mb-3 d-block d-md-none"
                  src="/imgs/championsClubBadge.svg"
                  alt="Champions Club Badge"
                />
                <p className="mb-5 w-100 text-center text-md-left large-body">
                  As a champion you unlock exclusive bonus prize draws along
                  with bonus draw tickets and exclusive sneak peek of upcoming
                  draws.
                </p>

                <Link passHref href="/subscribe" prefetch={false}>
                  <Button className="d-none d-lg-block" variant="dark-primary">
                    Join the Champions Club
                  </Button>
                </Link>
              </Col>

              <Col lg={6} className="position-static mb-4">
                <Row className="mb-n6">
                  {championClubBenefits.slice(0, 4).map(championClubBenefit => (
                    <Col
                      xs={6}
                      md={3}
                      lg={6}
                      key={championClubBenefit.id}
                      className="d-flex flex-column flex-lg-row mb-6 align-items-center"
                    >
                      {championClubBenefit.lottery_asset?.media_file.url && (
                        <img
                          loading="lazy"
                          src={
                            championClubBenefit.lottery_asset?.media_file.url
                          }
                          height={70}
                          width={70}
                          className="flex-shrink-0 flex-grow-0 mr-lg-3 mb-3 mb-lg-0"
                          alt={championClubBenefit.lottery_asset?.name}
                        />
                      )}
                      <h4 className="text-center text-lg-left mt-3 mt-md-0 large-body w-100">
                        {championClubBenefit.heading}
                      </h4>
                    </Col>
                  ))}
                </Row>
                <Link passHref href="subscribe" prefetch={false}>
                  <Button
                    className="d-block d-md-inline-block d-lg-none mt-6"
                    variant="dark-primary"
                  >
                    Join the Champions Club
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </footer>
  );
};

export default FooterWithChampionClubBenefits;
