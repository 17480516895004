interface Props {
  type?: 'primary' | 'secondary' | 'yellow';
}

const HamburgerMenu = ({ type = 'primary' }: Props) => {
  const fillColors = {
    primary: 'var(--primary)',
    secondary: 'var(--secondary)',
    yellow: 'yellow',
  };

  return (
    <svg fill={fillColors[type]} viewBox="0 0 100 80" width="1rem">
      <rect width="100" height="10" rx="8" />
      <rect y="30" width="100" height="10" rx="8" />
      <rect y="60" width="100" height="10" rx="8" />
    </svg>
  );
};

export default HamburgerMenu;
