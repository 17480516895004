import Link from 'next/link';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { Logo } from 'components/Icons';
import SocialMediaButtons from 'components/SocialMediaButtons/SocialMediaButtons';

interface IProps {
  facebookLotteryPage?: string;
  instagramLotteryUser?: string;
  twitterLotteryUser?: string;
  youtubeLotteryChannel?: string;
}

const FooterWithSocial = ({
  facebookLotteryPage,
  instagramLotteryUser,
  twitterLotteryUser,
  youtubeLotteryChannel,
}: IProps) => (
  <div className="bg-primary py-5">
    <Container>
      <Row>
        <Col lg={5}>
          <Row className="m-0 justify-content-between align-items-center">
            <Col>
              <Link href="/" passHref prefetch={false}>
                <a>
                  <Logo size={5} />
                </a>
              </Link>
            </Col>

            <Col xs="auto" lg={8}>
              <Link href="/buy-now" passHref prefetch={false}>
                <Button className="d-none d-lg-block" as="a" variant="danger">
                  BUY A TICKET TODAY!
                </Button>
              </Link>
              <Link href="/buy-now" passHref prefetch={false}>
                <Button className="d-block d-lg-none" as="a" variant="danger">
                  BUY NOW!
                </Button>
              </Link>
            </Col>
          </Row>

          <Row className="justify-content-end">
            <Col lg={8}>
              <div className="social-media my-4">
                <h6 className="text-white">Social links</h6>
                <SocialMediaButtons
                  facebookLotteryPage={facebookLotteryPage}
                  instagramLotteryUser={instagramLotteryUser}
                  twitterLotteryUser={twitterLotteryUser}
                  youtubeLotteryChannel={youtubeLotteryChannel}
                />
              </div>
            </Col>
          </Row>
        </Col>

        <Col lg={7}>
          <p className="text-white mb-0 mt-3">
            Other Surf Life Saving websites
          </p>
          <div>
            <Button
              as="a"
              className="text-white py-1 font-weight-semi-bold px-0"
              variant="link"
              href="https://www.slsfoundation.com.au/"
            >
              Visit Surf Life Saving Foundation
            </Button>
            <Button
              as="a"
              className="text-white py-1 font-weight-semi-bold px-0 ml-0 ml-md-3"
              variant="link"
              href="https://sls.com.au/"
            >
              Visit Surf Life Saving Australia
            </Button>
          </div>
          <div className="d-flex justify-content-end mt-5">
            <Button
              as="a"
              variant="link"
              target="_blank"
              className="p-0 d-inline-flex align-items-center"
              href="https://april9.com.au/"
              title="April9 Digital Consulting"
            >
              <small className="text-white mr-3">Developed by</small>
              <img src="/imgs/april9-logo.svg" alt="April9" width="60px" />
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default FooterWithSocial;
