import moment from 'moment';

import { DrawSteps, IDraw } from 'models/IDraw';

export const getDrawStep = (draw: IDraw): DrawSteps => {
  const now = moment().utc();
  const closeDate = moment.utc(draw.close_datetime);
  if (
    (closeDate.isBefore(now) &&
      draw._workflow_current_step === DrawSteps.open) ||
    draw._workflow_current_step === DrawSteps.close
  ) {
    return DrawSteps.close;
  }
  return draw._workflow_current_step;
};

export const getDrawStepName = (drawStep: DrawSteps): string | undefined => {
  switch (drawStep) {
    case DrawSteps.setup:
      return 'Setup';
    case DrawSteps.pendingApproval:
      return 'Pending Approval';
    case DrawSteps.upComing:
      return 'Up Coming';
    case DrawSteps.open:
      return 'Open';
    case DrawSteps.close:
      return 'Close';
    case DrawSteps.results:
      return 'Results';
    default:
      return undefined;
  }
};
