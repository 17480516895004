import moment from 'moment';

import { DrawSteps, IDraw } from 'models/IDraw';
import { IMenu } from 'models/IMenu';

import MENU_STRUCTURE from '../constants/menu';
import { getDrawStepName } from './getDrawStep';

const getMenu = (homeDraws: IDraw[] = [], autoDraws: IDraw[] = []): IMenu[] => {
  const formatDraw = (draw: IDraw): IMenu => {
    const closed =
      moment(draw.close_datetime).diff(moment()) <= 0 ||
      draw._workflow_current_step === DrawSteps.close;

    return {
      label: `${closed ? '(CLOSED)' : ''} Lottery ${draw.draw_no} - ${
        draw.menu_label || ''
      }`.trim(),
      href: `/lottery/${draw.draw_no}`,
      cyID: `lottery-${draw.draw_no}`,
    };
  };

  return MENU_STRUCTURE.reduce<IMenu[]>((acc, menu) => {
    switch (menu.cyID) {
      case 'home-lotteries':
        return homeDraws.length
          ? [
              ...acc,
              {
                ...menu,
                items: homeDraws
                  .sort((a, b) =>
                    moment(a.close_datetime).diff(moment(b.close_datetime)) > 0
                      ? -1
                      : 1,
                  )
                  .map(formatDraw),
              },
            ]
          : acc;
      case 'auto-lotteries':
        return autoDraws.length
          ? [
              ...acc,
              {
                ...menu,
                items: autoDraws
                  .sort((a, b) =>
                    moment(a.close_datetime).diff(moment(b.close_datetime)) > 0
                      ? -1
                      : 1,
                  )
                  .map(formatDraw),
              },
            ]
          : acc;
      default:
        return [...acc, menu];
    }
  }, []);
};

const getPreviewMenu = (previewDraws: IDraw[]): IMenu => {
  const formatDraw = (draw: IDraw): IMenu => {
    return {
      label: `${getDrawStepName(draw._workflow_current_step)} Lottery ${
        draw.draw_no
      } - ${draw.menu_label || ''}`.trim(),
      href: `/preview/lottery/${draw.draw_no}`,
      cyID: `preview-lottery-${draw.draw_no}`,
    };
  };

  const previewMenu = {
    label: 'Preview',
    cyID: 'preview',
    description: 'Preview Lottery pages',
    href: '',
    items: previewDraws
      .sort((a, b) =>
        moment(a.close_datetime).diff(moment(b.close_datetime)) > 0 ? -1 : 1,
      )
      .map(formatDraw),
  };
  return previewMenu;
};

export { getMenu, getPreviewMenu };
