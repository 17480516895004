import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { FiShoppingCart, FiUser, FiCreditCard } from 'react-icons/fi';

import { MyCartSteps } from 'contexts/cartContext';

const Steps = {
  [MyCartSteps.myCart]: {
    title: 'My Cart',
    titleWhenAuthenticated: undefined,
    titleWhenOrderIsPaid: undefined,
    stepNumber: 1,
    labelStep: 'Review tickets',
    icon: <FiShoppingCart />,
    route: '/transaction/cart',
  },
  [MyCartSteps.account]: {
    title: 'Sign in/create account',
    titleWhenAuthenticated: 'Confirm your details',
    titleWhenOrderIsPaid: undefined,
    stepNumber: 2,
    labelStep: 'check out',
    icon: <FiUser />,
    route: '/transaction/account',
  },
  [MyCartSteps.payment]: {
    title: 'Confirmation',
    titleWhenAuthenticated: undefined,
    titleWhenOrderIsPaid: 'Thank you for your purchase',
    stepNumber: 3,
    labelStep: 'Payment',
    icon: <FiCreditCard />,
    route: '/transaction/payment',
  },
};

const getTitle = ({
  step,
  isAuthenticated,
  orderIsPaid = false,
}: {
  step: MyCartSteps;
  isAuthenticated: boolean;
  orderIsPaid: boolean;
}) => {
  if (orderIsPaid) {
    return Steps[step].titleWhenOrderIsPaid;
  }

  return isAuthenticated && Steps[step].titleWhenAuthenticated
    ? Steps[step].titleWhenAuthenticated
    : Steps[step].title;
};

type IProps = {
  activeStep: MyCartSteps;
};

const StepsIcons = ({ activeStep }: IProps) => {
  const router = useRouter();

  return (
    <div className="d-flex align-items-center">
      {Object.values(Steps).map((cartStep, index) => (
        <Fragment key={cartStep.title}>
          {index !== 0 && (
            <span className="bg-black flex-grow-1" style={{ height: '1px' }} />
          )}

          <Button
            variant={index === activeStep ? 'secondary' : 'link'}
            size="sm"
            className="mx-2"
            disabled={index !== activeStep && index !== MyCartSteps.myCart}
            onClick={() => {
              if (index === MyCartSteps.myCart) {
                router.push(Steps[MyCartSteps.myCart].route);
              }
            }}
          >
            {cartStep.icon}
          </Button>
        </Fragment>
      ))}
    </div>
  );
};

export { getTitle, StepsIcons };

export default Steps;
