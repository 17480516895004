import Link from 'next/link';
import { FC, HTMLAttributes } from 'react';

import cx from 'classnames';

import { IMenu } from 'models/IMenu';

interface IProps extends HTMLAttributes<HTMLElement> {
  menu: IMenu[];
  menuItemProps?: any;
  activeMenuHref?: string;
  className?: string;
  menuClass?: string;
  withCYData?: boolean;
  activeMenuClass?: string;
  icon?: JSX.Element;
  multipleLevels?: boolean;
}

const MenuStructure: FC<IProps> = ({
  menu,
  withCYData,
  menuItemProps,
  activeMenuHref,
  className,
  menuClass,
  activeMenuClass,
  icon,
  multipleLevels,
  ...props
}) => {
  const renderMenu = (
    currentMenu: IMenu[],
    cb?: (nextMenu: IMenu) => JSX.Element | undefined,
  ) => {
    return (
      <ul className={cx('m-0', className)} {...props}>
        {currentMenu.map((menuItem: IMenu) => {
          return (
            <li
              key={menuItem.href}
              id={menuItem.href as string}
              className="p-0"
            >
              <Link href={menuItem.href} prefetch={false}>
                <a
                  {...menuItemProps}
                  data-cy={withCYData && menuItem.cyID}
                  role="button"
                  tabIndex={0}
                  className={cx(
                    menuItemProps?.className,
                    'd-flex py-1 px-3 justify-content-between text-decoration-none align-items-center',
                    activeMenuHref === menuItem.href
                      ? activeMenuClass
                      : menuClass,
                  )}
                  onMouseEnter={
                    menuItemProps?.onMouseEnter
                      ? () => {
                          menuItemProps?.onMouseEnter(menuItem);
                        }
                      : undefined
                  }
                  onKeyPress={
                    menuItemProps?.onClick
                      ? (ev: KeyboardEvent) => {
                          menuItemProps?.onClick(menuItem, ev);
                        }
                      : undefined
                  }
                  onClick={
                    menuItemProps?.onClick
                      ? (ev: MouseEvent) => {
                          menuItemProps?.onClick(menuItem, ev);
                        }
                      : undefined
                  }
                >
                  <span>{menuItem.label}</span>
                  {!!menuItem?.items?.length && icon}
                </a>
              </Link>
              {cb && !!menuItem?.items?.length && cb(menuItem)}
            </li>
          );
        })}
      </ul>
    );
  };

  return renderMenu(
    menu,
    multipleLevels
      ? (menuItem: IMenu) => {
          return menuItem.items?.length
            ? renderMenu(menuItem.items)
            : undefined;
        }
      : undefined,
  );
};

export default MenuStructure;
